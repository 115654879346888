// importing VUE X
import Vue from 'vue';
import Vuex from 'vuex';
// import dependency to handle HTTP request to our back end
import axios from 'axios';
// Setting up the cookie
// import zohosdk from 'zoho-node-sdk'
// import zohosdkToken from 'zoho-node-sdk/tool/gentoken';

// importing Firebase
import {getAuth, setPersistence, signOut, browserSessionPersistence, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth'
import { getDatabase, ref, set, onValue, update, push, query, orderByChild, equalTo } from "firebase/database";
// import { push } from 'core-js/core/array';

// JSON file reference for the basic database system
import UploadJSON from '../assets/json/upload.json'

Vue.use(Vuex);

// Storage
export const store = new Vuex.Store({
  state: {
    // App Loading Stats
    userError: false,
    loadingState:{
      mainLoading: false,
      compressorLoadingStats: false,
    },
    successFlag: false,
    successMsg: 'Operation Successful',
    // Services Status
    services:{

    },
    DialogText: {
      heading: 'Please Login',
      description: 'Please Login Description'
    },
    SnackBarText: 'Please Login',
    // Application meta Information
    appinfo : {
      name : 'ABSA',
      fullname: 'ABSACO Container tracking system',
      author : 'radean',
      authorEmail : 'radeanf@gmail.com',
      developer : 'radean',
      company : 'Astrone',
      version : 'Alpha 0.12zX',
      status : true,
      startDate: '10-03-2021',
      endDate: '10-03-2022',
      mode: '',
      broadcast: true,
      subscription: true
    },

    // App Authentication
    appAuth: {
      ClientID: '1000.H5ALTHIR2E0M316C2D89PM59LZUJKR',
      ClientSecret: '3aadda769ceddd6e06b8a2b78e30b4237c6cc96a2e',
      AuthToken: '',
      RefreshToken: '1000.e7e7537159ad66a3864b137885ff0901.0ababb5923ee6ecf466b8d5e8ca7acaf',
      accessCode: ''
    },
    submittedContainerInfo: {
      trackingID: '',
      NumberTypeIndex: ''
    },
    // App Main Data
    // Container
    containerinfo: {
      name: 'Base',
      number: 1549685324,
      hbldetails: {
        number: '',
        origin: '',
        destination: '',
        packDescription: '',
        height: '',
        volume: ''
      }
    },
    // usersession
    userinfo: {},
    user: null,
  },
  mutations: {
    // ------
    // ------
    // GUI
    setAppHeader(state, payload){
      state.app.header.name = payload.name;
      state.app.header.location = payload.location;
    },
    setUser (state, payload){
      state.user = payload;
    },
    setUserInfo (state, payload){
      state.userinfo = payload;
    },
    'SET_MAIN_LOADING'(state, payload){
      state.loadingState.mainLoading = payload;
    },
    'SET_USER_INFORMATION'(state, payload){
      state.loadingState.userinfo = payload;
    },
    'SET_DIALOGTEXT'(state, payload){
      state.DialogText.heading = payload.heading;
      state.DialogText.description = payload.description;
    },
    'SET_SNACKBARTEXT'(state, payload){
      state.SnackBarText = payload;
    },
    // ------
    // ------
    // LOGIC
    'SET_CONTAINER_NUMBER' (state, payload) {
      state.containerinfo.number = payload;
    },
    'SET_ACCESS_CODE' (state, payload) {
      state.appAuth.accessCode = payload;
    },
    'SET_SUBMITTED_CONTAINER_INFO' (state, payload) {
      state.submittedContainerInfo.trackingID = payload.trackingID;
      state.submittedContainerInfo.NumberTypeIndex = payload.NumberTypeIndex;
    },
    'SET_CONTAINER_INFORMATION' (state, payload) {
      // MUTATING THE VARIABLE

      // TEMPLATE DATA COMING 
      // {
      //   "Serial":"1","SO_Receiving_Date":"24-Jan-2021",
      //   "Sales_Order_Number":"3200090664",
      //   "Container_Number":"BEAU4544782",
      //   "Seal_Number":"44466.0",
      //   "Bayan_Number":"5021",
      //   "Bayan_Date":"01-Feb-2021",
      //   "Shipping_Line":"LOGISTICS+ HLCL",
      //   "Bill_of_Lading_Number":"HLCUDM2210110242",
      //   "Container_Type":"40' DC",
      //   "Previous_Depot_Name":"2314",
      //   "Current_Depot_Name":"ABSJ",
      //   "Depot_IN_date":"27-Jan-2021",
      //   "Truck_Number_IN":"FIT2107",
      //   "Next_Depot_Location":"JUBAIL PORT",
      //   "Depot_OUT_date":"02-Feb-2021",
      //   "Truck_Number_OUT":"ALS4963",
      //   "Ageing":"7",
      //   "Order_Status":"SAILED",
      //   "Vessel_Name":"CSAV TYNDALL",
      //   "Voyage_Number":"2105W"
      // }

      // Setting required Data in order

      // fetchedcontainerInfo.Ageing = containerData.Ageing;
      // fetchedcontainerInfo.Container_Number = containerData.Container_Number;
      // fetchedcontainerInfo.Container_Type = containerData.Container_Type;
      // fetchedcontainerInfo.Shipping_Line = containerData.Shipping_Line;
      // fetchedcontainerInfo.Bill_of_Lading_Number = containerData.Bill_of_Lading_Number;
      // fetchedcontainerInfo.Order_Status = containerData.Order_Status;
      // fetchedcontainerInfo.Seal_Number = containerData.Seal_Number;
      // fetchedcontainerInfo.Vessel_Name = containerData.Vessel_Name;
      // fetchedcontainerInfo.Voyage_Number = containerData.Voyage_Number;
      // fetchedcontainerInfo.Vessel_Name = containerData.Vessel_Name;
      // fetchedcontainerInfo.Vessel_Name = containerData.Vessel_Name;

      let breakData =Object.values(payload)
      state.containerinfo = breakData[0];
    }
  },
  actions: {
    // GUI
    // ---------
    setMainLoading({commit}, payload){
      commit('SET_MAIN_LOADING', payload);
    },
    setDialog({commit}, payload){
      commit('SET_DIALOGTEXT', payload);
    },
    setSnackBarText({commit}, payload){
      commit('SET_SNACKBARTEXT', payload);
    },
    // USERS
    // USER AUTHENTICATION
    userSignUp({commit}, payload){
      // let userID;
      const auth = getAuth() 
      const db = getDatabase();
      createUserWithEmailAndPassword(auth, payload.email, payload.password)
        .then((user) => {
          console.log('User Information', user.user.uid)
          commit('SET_MAIN_LOADING', true);
          const userInfo = {
            uniqueId: user.user.uid,
            password: payload.password,
            email: payload.email,
            fullname: payload.fullname
          };
          set(ref(db, 'users/' + userInfo.uniqueId), {
            uniqueId: userInfo.uniqueId,
            password: userInfo.password,
            email: userInfo.email,
            fullname: userInfo.fullname,
            access_token: '',
            lastSearchNumber: '',
            lastSearchType: ''
          });
        })
    },
    userSignIn({commit}, payload){
      const auth = getAuth();
      signInWithEmailAndPassword(auth, payload.email, payload.password)
        .then(onAuthStateChanged(auth, (appUser) => {
          commit('setUser', appUser)
          // setting the persistent values
          setPersistence(auth, browserSessionPersistence)
        })).catch(
          error => {
            console.log(error);
        })
    },
    // User Session Check
    userSession({dispatch, commit}){
      // Checking Firebase user
      // console.log('Checking the user loggin status')
      const auth = getAuth();
      onAuthStateChanged(auth, (appUser) => {
        if(appUser) {
          // setting Authorization
          commit('setUser', appUser);
          dispatch('subUserInfo');
        }else{
          commit('setUser', null);
        }
      });

      // checking the nPM plugin]
      // let ZohoToken = zohosdkToken;
      // console.log(ZohoToken);
    },
    // user information update
    subUserInfo({commit, getters}){
      // let userID;
      const db = getDatabase();
      // Setting Loading
      commit('SET_MAIN_LOADING', true);
      // setting user information
      onValue(ref(db, 'users/' + getters.user.uid + '/'), (snapshot) => {
        const data = snapshot.val();
        // console.log(data);
        commit('setUserInfo', data);
      })
      
    },
    // user Log out
    userSignOut({commit}){
      const auth = getAuth();
      commit('SET_MAIN_LOADING', true);
      signOut(auth).then(() =>{
        // setting App Header
        commit('setUserInfo', null);
        commit('setUser', null);
        commit('SET_MAIN_LOADING', false);
      });
    },


    // LOGIC
    // -------------
    setContainerNumber({commit}, payload) {
      commit('SET_CONTAINER_NUMBER', payload);
    },
    setSubmittedContainerInfo({commit}, payload) {
      commit('SET_SUBMITTED_CONTAINER_INFO', payload);
    },
    GetAuthentication({commit}) {
      // Getting Registration Authorization 
      console.log("Getting Authorization Begin...")

      // Getting the AccessCode
      let AppAuth = this.state.appAuth;

      console.log("Auth Object", AppAuth)

      let data = new FormData();
      // let data = '';
      data = {
        'criteria': 'Sales_Order_Number==/"3200097152/"' 
      };

      axios({
        method: 'get',
        url: 'https://creator.zoho.com/api/v2/albarrakshippingcoltd75/digital-space/report/Master_Inventory_2_0_Report?' + 'criteria=Container_Number=="HMMU6293020"',
        headers: {
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': "http://localhost:3000",
          "Authorization": 'Zoho-oauthtoken ' + AppAuth.accessCode,
        },
        data: data
        }).then((response) => {
          console.log("Getting Authentication",JSON.stringify(response.data))
          commit('SET_CONTAINER_INFORMATION', response.data.data[0])

      })
    },
    setContainerInformation({commit,}, payload) {
      // Getting the container details from the server
      let data = new FormData();
      console.log("Setting Tracking ID (From the STATE): ", payload.id)
      data.append('id', payload.id);
      axios({
        method: 'post',
        url: 'https://getabsa.techerupt.com',
        headers: {
          // "Access-Control-Allow-Origin" : "*",
          // 'Access-Control-Allow-Methods': 'POST',
          'Content-Type': 'multipart/form-data',
        },
        data: data
        }).then((response) => {
        console.log(JSON.stringify(response.data))
        // After getting all the data from the Server
        // COMMITING MUTATION
        commit('SET_CONTAINER_INFORMATION', response.data);
          
        })
    },
    setAccessCode({commit, getters}, payload) {
      // Getting the container details from the server
      // Setting Loading
      commit('SET_MAIN_LOADING', true);
      if (getters.user != null) {
        // console.log('Got the hit to store the access codes', payload);
        // Setting the current user
        let user = getters.user;
        const db = getDatabase();
        // getting the current user from the server
        update(ref(db, 'users/' + user.uid), {
          access_token: payload
        }).catch(
          error => {
            console.log(error);
        })
        commit('SET_ACCESS_CODE', payload);
        // Setting Loading
        commit('SET_MAIN_LOADING', false);
      }

      // Setting Loading
      commit('SET_MAIN_LOADING', false);
      // this.dispatch('GetAuthentication');
    },
    setlastContainerNumber({getters}, payload) {
      // Getting the container details from the server
      // if the user in not null then
      if (getters.user != null) {
        // Setting the current user
        let user = getters.user;
        const db = getDatabase();
        // getting the current user from the server
        update(ref(db, 'users/' + user.uid ), {
          lastSearchNumber: payload.searchNumber,
          lastSearchType: payload.searchType
        }).catch(
          error => {
            console.log(error);
        })
      }
      // commit('SET_ACCESS_CODE', payload);

      // this.dispatch('GetAuthentication');
    },


    // FIREBASE
    //--------------------
    GetContainerInformationByContainerNumber({commit, getters}) {
      // Getting the container Number from the state
      let containerNumber = getters.submittedcontainerInfo;

      console.log("Submitted DATA", containerNumber.trackingID)

      const db = getDatabase();

      const dataS = query(ref(db, 'container/'), orderByChild('Container_Number'), equalTo(containerNumber.trackingID));
      // console.log(dataS);

      onValue(dataS , (snapshot) => {
        let data = snapshot.val();
        console.log(data);
        commit('SET_CONTAINER_INFORMATION', data);
      })



    },

    UploadJsonUtility() {
      // Getting the file from the json repo
      // setting the firebase server according to the file
      // const auth = getAuth() 
      const db = getDatabase();

      // Getting the JSON file from the directory,

      for (let i = 0; i < UploadJSON.length; i++) {
        // const element = i[i];
        const element = UploadJSON[i];
        push(ref(db, 'container/'), element);
      }
    },
    CleanFirebaseDB() {
      // Cleaning up the whole repo
      // let userID;
      // const auth = getAuth() 
      const db = getDatabase();
      //Cleanup the DB
      
      set(ref(db, 'container/'), {
      });
    }
  },
  getters: {
    // Application Details
    appinfo (state){
      return state.appinfo
    },
    userinfo (state) {
      return state.userinfo
    },
    appAuth (state){
      return state.appAuth
    },
    user (state){
      return state.user
    },
    containerInfo (state) {
      return state.containerinfo
    },
    submittedcontainerInfo (state) {
      return state.submittedContainerInfo
    },
    loadingState (state) {
      return state.loadingState
    },
    dialogText (state) {
      return state.DialogText
    },
    snackBarText (state) {
      return state.SnackBarText
    },
    avatarText(state) {
      if (state.user) {
        let text = String(state.user.email)
        let out = text.substring(0, text.lastIndexOf("@"))
        return out[0]
      }
      return null;
    }
  }
});