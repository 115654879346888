<template>
  <v-app >
    <v-main class="bgBack">
        <!-- DIALOGS -->
        <v-dialog
          v-model="dialog"
          max-width="400"
        >
          <v-card>
            <v-card-title >
              <h2> {{ getDialogText.heading }} </h2>
            </v-card-title>

            <v-card-text>
              {{ getDialogText.description }}
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="btGradient"
                dark
                x-small
                @click="dialog = false"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- SNACK BARS -->
        <v-snackbar
          v-model="snackbar"
          color="btGradient"
          left
          shaped
          top
          :timeout="4000"
        >
          {{ snackbarText }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              HIDE!
            </v-btn>
          </template>
        </v-snackbar>
        <!-- avatar -->
        <div class="text-right absolute right mt-14 ml-12" style="z-index: 99" v-show="getAvatarText">
          <v-menu 
            offset-x
          >
            <template v-slot:activator="{ on, attrs }" >
              <v-avatar
                color="btGradient"
                class="mt-5 mr-10"
                style="color: white; text-transform: uppercase; font-weight: 700; font-size: 32px"
                size="56"
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-badge
                  dot
                  color="orange"
                  icon="mdi-iobroker"
                >
                  {{getAvatarText}}
                </v-badge>
              </v-avatar>  
            </template>  
            <v-list dark>
              <v-list-item
                v-for="(item, index) in avatarMenuItems"
                :key="index"
              >
                <v-list-item-title @click='listMenuActions(item)'>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      <router-view v-on:dialog="OpenDialog"></router-view>
      <!-- <HelloWorld/> -->
    </v-main>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
// eslint-disable-next-line no-unused-vars
import Search from './components/Search.vue';
// 

export default {
  name: 'App',

  components: {
    // HelloWorld,
    // eslint-disable-next-line vue/no-unused-components
    Search,
  },

  data: () => ({
    //
    dialog: false,
    snackbar: false,
    snackbarText: 'Pleases Login!',

    //GUI
    avatarMenuItems: [
      { title: 'About', function: 'dialog', heading: 'About', description: 'Its all about the containers and the tracking services'},
      { title: 'SignOut', function: 'signout' },
    ]
  }),
  created() {
    console.log("App Started!");
    

    //checking the user login
    this.checkUserLogin(); 
    // setInterval(() => {
    //   this.checkUserLogin(); 
    // }, 10000)
  },
  computed: {
    getUserInfo(){
      return this.$store.getters.userinfo;
    },
    getUser(){
      return this.$store.getters.user;
    },
    getDialogText(){
      return this.$store.getters.dialogText;
    },
    getSnackBarText(){
      return this.$store.getters.snackBarText;
    },
    getAvatarText(){
      return this.$store.getters.avatarText;
    }
  },
  methods: {
    //Login
    checkUserLogin() {
      // Checking if the user is already signed In
      this.$store.dispatch('userSession').then(() => {
        // Check is user is LOGGED IN?
        setTimeout(() => {
          if (this.getUser == null || this.getUser == undefined){
            // Pushing the Snack Bar
            this.pushSnackBar("Please Login!");
            // User information
            console.log(this.getUser);
            //if user is null then the app will notify after 2 seconds
            if (this.$route.name != 'Login' && this.$route.name != 'Registration') {
              setTimeout(() => {
                console.log("YOU ARE NOT LOGGED IN");
                this.$router.push({'name': 'Login'})
                this.OpenDialog({heading: 'The Session is not valid', description: 'Please login again with correct credentials'})
              }, 2000)
            }
          }else{
            // Pushing the Snack Bar
            this.pushSnackBar("Welcome!");
          }
        }, 2000)
      });
    },
    // GUI
    OpenDialog(payload) {
      //
      // console.log(payload);
      // Setting the Dialog State
      let dialogText = {
        heading: payload.heading,
        description: payload.description
      }
      this.$store.dispatch('setDialog', dialogText)

      // Setting the dialog opened
      this.dialog = true
    },
    listMenuActions(payload){
      // Getting the type of action
      switch (payload.function) {
        case 'dialog':
          // eslint-disable-next-line no-case-declarations
          let data = {
            heading: payload.heading,
            description: payload.description
          }
          this.OpenDialog(data)
          break;
        case 'signout':
          // console.log('Signing Out!');
          this.$store.dispatch('userSignOut');

          // Recheck the user
          this.checkUserLogin();
          break;
      
        default:
          break;
      }
    },
    pushSnackBar(payload) {
      // Setting up the Welcome
      this.$store.dispatch('setSnackBarText', payload)
      this.snackbarText = this.getSnackBarText;
      this.snackbar = true
    }
  },
  watch: {
    // On User Infomation Verify
    // getUser (value){
    //   if (value == null && value == undefined) {
    //     console.log('Watch Kicked')
    //     this.checkUserLogin();
    //   } 
    // },
  }
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600&display=swap');

html,body{
  font-family: 'Titillium Web', sans-serif;
  font-size: 16px;
  font-weight: 400;
  /* user-select: none; */

}
.bgBack {
  background-image: url("assets/traceBG.jpg");
  background-repeat: repeat;
  background-size: 100%;
  color: #222;
}
h1 {
  font-weight: 400;
  font-family: 'Titillium Web', sans-serif;
  font-size: 38px;
}
h1.crisp {
  font-weight: 200;
  letter-spacing: 2px;
  font-family: 'Titillium Web', sans-serif;
  font-size: 38px;
}
h2 {
  font-weight: 600;
  font-family: 'Titillium Web', sans-serif;
  font-size: 28px;
}
h3 {
  font-weight: 400;
  font-family: 'Titillium Web', sans-serif;
  font-size: 20px;
}
h3.crisp{
  font-weight: 200;
  font-family: 'Titillium Web', sans-serif;
  font-size: 20px;
}
h4 {
  font-weight: 300;
  font-family: 'Titillium Web', sans-serif;
  font-size: 16px;
}
h4.crisp{
  font-weight: 200;
  font-family: 'Titillium Web', sans-serif;
  font-size: 16px;
}
.btGradient {
  background: #141E30;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #243B55, #141E30);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #243B55, #141E30); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.btGradientSec {
  background: #c0c0c0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #e0e0e0, #c0c0c0);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #e0e0e0, #c0c0c0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.absolute {
  position: absolute;
}
.absolute.right {
  right: 0;
}
</style>