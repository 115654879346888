<template>
<div>
  <Particles
      id="tsparticles"
      style="position: absolute, width: 100%,"
      :options="{
          background: {
              color: {
                  value: 'transparent',
              }
          },
          fpsLimit: 30,
          interactivity: {
              detectsOn: 'canvas',
              events: {
                  onClick: {
                      enable: true,
                      mode: 'push'
                  },
                  onHover: {
                      enable: false,
                      mode: 'repulse'
                  },
                  resize: true
              },
              modes: {
                  bubble: {
                      distance: 600,
                      duration: 10,
                      opacity: 0.8,
                      size:32
                  },
                  push: {
                      quantity: 1
                  },
                  repulse: {
                      distance: 200,
                      duration: 0.4
                  }
              }
          },
          particles: {
              color: {
                  value: '#000'
              },
              links: {
                  color: '#444',
                  distance: 150,
                  enable: true,
                  opacity: .3,
                  width: .5
              },
              collisions: {
                  enable: false
              },
              move: {
                  direction: 'none',
                  enable: true,
                  outMode: 'none',
                  random: false,
                  speed: .2,
                  straight: true
              },
              number: {
                  density: {
                      enable: true,
                      value_area: 1200
                  },
                  value: 64
              },
              opacity: {
                  value: 0.25
              },
              shape: {
                  type: 'polygon',
                  options: {
                    polygon: [
                    {
                      sides: 3
                    }
                    ]
                  },
              },
              size: {
                  random: true,
                  value: 5
              }
          },
          detectRetina: false
      }"
    />
  <v-container>
     <v-row class="text-center">
      <v-col cols="12">
        <!-- Logo -->
        <img src="../assets/logo.png" class="my-5 mb-0 logo" height="128" />
      </v-col>

      <v-col class="mb-4" cols="12">
        <h1 class="display-2 mb-1">
          {{ getAppInfo.name }}
        </h1>
        <h4 class="display-5 mb-1">
          {{ getAppInfo.fullname }} 
        </h4>
      </v-col>

      <!-- Tracking widget -->
      <v-col class="mb-4" cols="12">
        <v-card
          class="mx-auto"
          style="background: rgba(230,230,230,0.5);"
          elevation="20"
          max-width="600"
          :loading="valid"
        >

          <v-card-title class="pb-0">
            <!-- Tracking ID: -->
          </v-card-title>

          <!-- <v-card-subtitle class="pb-0">
            SUB-TITLE
          </v-card-subtitle> -->

          <v-card-text class="text--primary">
            <v-form >
              <v-row>
              <v-col class="ml-0" cols="8">
                <v-text-field
                  v-model="trackingID"
                  :rules="trackingfieldrules"
                  :counter="40"
                  placeholder="123456789"
                  filled
                  label="TRACKING NUMBER"
                  required
                  hint="Please enter Container, B/L, or Reference Number"
                ></v-text-field>
              </v-col>
              <v-col class="ml-0" cols="4">
                <v-select
                  :items="trackingNumberTypes"
                  :placeholder="trackingNumberTypes[0]"
                  v-model="NumberType"
                  filled
                ></v-select>
              </v-col>
              </v-row>

            </v-form>
            <!-- buttons -->
            <v-list-item class="grow" >
              
              <v-col cols="3">
              </v-col>

              <v-col cols="6">
                <v-row
                justify="center"
                >
                  <v-btn
                    class="ml-2 btGradient"
                    elevation="15"
                    dark
                    block
                    v-on:click="Practing"
                  >
                    Search
                  </v-btn>
                </v-row>
              </v-col>
            </v-list-item>
          </v-card-text>
        

          
        </v-card>
      </v-col>

      <v-col
        class="mb-1"
        cols="12"
      >
        <h3 class="headline font-weight-bold mb-1">
          How it works?
        </h3>

        <p>
          Please enter the reference number is required to use the services, please add container, tracking, or BL Number. <br>
          in case of any issue please contact on <a href="mailto:abc@absaco.com" >abc@absaco.com</a> <br>
          <span style="color: #999; font-weight: bolder"> 
          App Version
          {{ getAppInfo.version }}
          </span>
        </p>



      </v-col>
      

    </v-row>
  </v-container>
</div>
  
</template>

<script>

  export default {
    name: 'Home',

    data: () => ({
    valid: false,

    // META APP
    trackingNumberTypes: ["Container #", "B/L #", "Reference/Booking #", "Sales Order #"],

    trackingID: 'CBHU9587903',
    NumberType: 'Container #',
    trackingfieldrules: [
        v => !!v || 'A Number is required',
    ],

    }),
    methods: {
      Practing() {
        // console.log("Log Infront");
        // console.log(this.NumberType);
        this.valid = true;


        //-----------------
        // ZOHO
        //-----------------
        // this.$store.dispatch('setlastContainerNumber', {searchNumber: this.trackingID, searchType: this.NumberType}).then(() => {
        //   // Getting the access code
        //   setTimeout(() => {
        //     this.valid = false;
        //     //Setting up the routes
        //     let AccessURL = 'https://accounts.zoho.com/oauth/v2/auth?client_id='+ this.getAppAuth.ClientID +'&response_type=token&scope=ZohoCreator.report.READ&redirect_uri=http://localhost:3000/search';
        //     // this.$router.push(AccessURL)
        //     window.location.href = AccessURL;
        //   }, 1000)
        // })

        this.valid = false;
        // setTimeout(() => {
        //   this.valid = false;
        //   //Setting up the routes
        //   let AccessURL = 'https://accounts.zoho.com/oauth/v2/auth?client_id='+ this.getAppAuth.clientID +'&response_type=token&scope=ZohoCreator.report.READ&redirect_uri=http://localhost:8080/search';
        //   this.$router.push(AccessURL)
        // }, 1000)


        //-----------------
        // FIREBASE
        //-----------------
        this.NumberSubmit();


      },

      // Submission of the search Number
      NumberSubmit() {
        // Dertermining the Selected Number
        // console.log("Getting the Number Type", this.trackingID, this.GetSubmittedNumberType());



        // REDIRECTING TO SEARCH PAGE
        this.valid = true;

        this.$store.dispatch('setSubmittedContainerInfo', {
          trackingID: this.trackingID,
          NumberTypeIndex: this.GetSubmittedNumberType()
        })

        setTimeout(() => {
          this.valid = false;
          //Setting up the routes
          this.$router.push("search/")
        }, 1000)

        //Consoling the values

        

        //Getting the Authorization from the state
        //Sending the instructions to the state
        // let pageId = String(this.$route.params.id);
        //this.$store.dispatch('GetAuthentication')
        // this.$store.dispatch('setContainerInformation', {
        //   id: pageId
        // })
      },

      // -----------------------------
      // UTILITIES
      // Submission of the search Number
      GetSubmittedNumberType() {
        // Dertermining the Selected Number
        let SubmittedNumberType = 0; // Default is Container_Number

        // Iterating the Available Number Types
        for (let i = 0; i < this.trackingNumberTypes.length; i++) {
          const element = this.trackingNumberTypes[i];

          // Matching and setting the get Number
          if (this.NumberType == element) {
            SubmittedNumberType = i;
          }
        }
        return SubmittedNumberType
        //Consoling the values
      },
    },
    computed: {
      getAppInfo(){
        return this.$store.getters.appinfo;
      },
      getAppAuth(){
        return this.$store.getters.appAuth;
      }
    }
  }
  
</script>

<style scoped>
/*Disabling the Scrolls*/
body {
  overflow: hidden;
}

#tsparticles {
  position: absolute;
  width: 100%;
  z-index: 0;
}
img .logo {
  z-index: 2;
}
.boxColor {
   background: rgba(230,230,230,0.6); 
}
</style>