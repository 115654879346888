<template>
<div>
  <!-- App Bar Main Container from the top black pointer -->
  <v-app-bar
    dark
  >
    <img src="../assets/logo.png"  height="32" />
    <a href="../" style="color: black; text-decoration: none"> 
      <v-toolbar-title style="color: #FFF;"> 
        <span class="ml-2"> {{ getAppInfo.name }} </span>
      </v-toolbar-title> 
    </a>
    <v-spacer></v-spacer>
    <v-btn icon>
      <v-icon>mdi-information</v-icon>
    </v-btn>
    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        gradient="to right, #000000, #434343"
      ></v-img>
    </template>
  </v-app-bar>

  <!-- Overlay until the Authorization -->
  <v-overlay :value="overlay" opacity=".98">
    <v-container>
      <v-row justify="center" >
        <h1 class="crisp">Please Wait</h1>
      </v-row>
      <v-row justify="center" class="mb-10">
        <h3 class="crisp">while we are connecting with our services</h3>
      </v-row>
      <v-row justify="center" class="mb-10">
        <v-progress-circular
          indeterminate
          width="1"
          size="64"
        ></v-progress-circular>
      </v-row>
      <v-row justify="center">
        <v-btn
          class="white--text btGradient"
          @click="overlay = !overlay"
        >
          Expedite Authentication
          <v-icon right>
            mdi-open-in-new
          </v-icon>
        </v-btn>
      </v-row>
    </v-container>
  </v-overlay>

  <!-- Map Tracking -->
  <v-container
    dark
    class="radius boxColor"
    style="box-radius: 20px;"
  >


    <!-- Page Heading -->
    <h1 class=" my-3">
          Container Track and Trace
    </h1>
    <!-- Search Pager Header -->
    <v-row
    >
      <v-col
        class=""
        cols="12"
      >
        <h2 class="text-center">
          Tracking Map
        </h2>
      </v-col>
    </v-row>
    <v-row
    >
      <v-col
        class="text-center"
        cols="12"
      >
        <!-- MAP EMBEDDED -->
        <!-- <h3>Open Map</h3> -->
        <!-- <img src="../assets/map.png"  height="100%" /> -->
        <!-- <vue-iframe
          src="https://sirius.searates.com/tracking/multitracking"
          allow="camera *; geolocation *; microphone *; autoplay *"
          frame-id="my-ifram"
          @load="onLoad"
          name="my-frame"
          width="150px"
          height="200px"
        /> -->
        <!-- Open Up the Map -->
          <v-btn
            elevation="2"
            class="btGradientSec"
            raised
            x-large
            v-on:click="OpenMap"
          >Open Map<v-icon>mdi-map-marker-path</v-icon>
          </v-btn>
      </v-col>
    </v-row>
  </v-container>
  

  <!-- Parallax Content Manager -->
  <v-container
    dark
    class="radius boxColor"
    style="box-radius: 20px;"
  >

    <!-- Search Pager Header -->
    <v-row
    >
      <v-col
        class=""
        cols="12"
      >
        <h2>
          Container Details
        </h2>
      </v-col>
    </v-row>
    <!-- Tracking Details -->
    <v-row>
      <v-col cols="6">
        <v-row
        >
          <!-- Container Number  -->
          <v-col
            class=""
            cols="6"
          >
            Container #:
            <h3>
              {{getContainerInfo.Container_Number}}
            </h3>
          </v-col>
          <!-- Container Type  -->
          <v-col
            class=""
            cols="6"
          >
            Container Type:
            <h3>
              {{getContainerInfo.Container_Type}}
            </h3>
          </v-col>
          <!-- Status  -->
          <v-col
            class=""
            cols="6"
          >
            Sales Order Number:
            <h3>
              {{getContainerInfo['Sales_Order_Number-Sales_Order_Number']}}
            </h3>
          </v-col>
          <!-- Carrier  -->
          <v-col
            class=""
            cols="6"
          >
            Voyage Number: 
            <h3>
              {{getContainerInfo['Sales_Order_Number-Voyage_Number']}}
            </h3>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row
        >
          <!-- Container Number  -->
          <v-col
            class=""
            cols="6"
          >
            Bill of Landing Number:
            <h3>
              {{getContainerInfo.Bill_Of_Lading_Number}}
            </h3>
          </v-col>
          <!-- Container Type  -->
          <v-col
            class=""
            cols="6"
          >
            Order Status:
            <h3>
              {{getContainerInfo['Sales_Order_Number-Order_Status']}}
            </h3>
          </v-col>
          <!-- Status  -->
          <v-col
            class=""
            cols="6"
          >
            Shipping Mode:
            <h3>
              {{getContainerInfo['Sales_Order_Number-Shipping_Mode']}}
            </h3>
          </v-col>
          <!-- Carrier  -->
          <v-col
            class=""
            cols="6"
          >
            Vessel Number: 
            <h3>
              {{getContainerInfo['Sales_Order_Number-Vessel']}}
            </h3>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    
    <!-- Tracking Update Heading -->
    <v-row
    >
      <v-col
        class=""
        cols="6"
      >
        <h2 class="">
          Tracking Update
        </h2>
      </v-col>
    </v-row>
    <!-- Tracking Update -->
    <v-row
    >
      <!-- Container Track Update  -->
      <v-col
        cols="6"
      >
        <v-row
        >
          <v-col
            cols="6"
          >
            Origin Port:
            <h3>
              {{getContainerInfo['Sales_Order_Number-Port_Of_Loading']}}
            </h3>
          </v-col>
          <!-- Next Depot Name  -->
          <v-col
            cols="6"
          >
            Next Depot Name:
            <h3>
              {{getContainerInfo['Sales_Order_Number-Port_Of_Discharge']}}
            </h3>
          </v-col>
          <!-- Bill of Landing Port port  -->
          <v-col
            cols="6"
          >
            Material:
            <h3>
              {{getContainerInfo['Sales_Order_Number-Material']}}
            </h3>
          </v-col>
          <!-- Order Status -->
          <v-col
            cols="6"
          >
            Booking Party: 
            <h3>
              {{getContainerInfo['Sales_Order_Number-Booking_Party']}}
            </h3>
          </v-col>
          <!-- Seal_Number  -->
          <v-col
            cols="6"
          >
            Actual Sailing Date:
            <h3>
              {{getContainerInfo['Sales_Order_Number-Actual_sailing_date']}}
            </h3>
          </v-col>
          <!-- Vessle Nammet  -->
          <v-col
            cols="6"
          >
            KPI:
            <h3>
              {{getContainerInfo['Sales_Order_Number-KPI']}}
            </h3>
          </v-col>
        </v-row>
        
      </v-col>

      
    </v-row>
    <!-- Shipping Segment -->
    <v-row
    >
      <v-col
        class=""
        cols="12"
      >
        <h2 class="my-3">
          HBL Detail
        </h2>
      </v-col>
    </v-row>
    <!-- Shipping Table  -->
    <v-data-table
    :headers="hblTableHeader"
    :items="hbldetails"
    :items-per-page="10"
    class="elevation-5"
    ></v-data-table>

    <v-row
    >
      <v-col
          class="text-center my-5"
          cols="12"
        >
          <v-btn
            elevation="2"
            raised
            x-large
            v-on:click="FetchContainerInfo"
          ><v-icon>mdi-cloud-search Seach More</v-icon>
          </v-btn>
          <!-- Refresh -->
          <v-btn
            elevation="2"
            raised
            x-large
            v-on:click="RefreshContainerInfo"
          ><v-icon>mdi-refresh-circle</v-icon>
          </v-btn>
      </v-col>
    </v-row>
  </v-container>

</div>
  
</template>

<script>

  export default {
    name: 'Search',

    data: () => ({
    valid: false,

    // Overlay
    overlay: true,

    // META APP
    trackingNumberTypes: ["Container #", "B/L #", "Reference #"],

    containerID: "BEAU4544782",
    //SELECT ID QUERY
    trackingInfo: [],


    trackingID: '',
    NumberType: '',
    trackingfieldrules: [
      v => !!v || 'A Number is required',
    ],
    hblTableHeader: [
      {
        text: 'HBL No.',
        align: 'start',
        sortable: false,
        value: 'HBLNo',
      },
      { text: 'Origin', value: 'origin' },
      { text: 'Destination', value: 'destination' },
      { text: 'Pack Description', value: 'description' },
      { text: 'Weight', value: 'weight' },
      { text: 'Height', value: 'height' },
    ],
    hbldetails: [
      {
        HBLNo: 'No Information',
        origin: 'No Information',
        destination: 'No Information',
        description: 'No Information',
        weight: 'No Information',
        height: 'No Information',
      },
    ]
    }),
    methods: {
      Practing() {
        console.log("Log Infront");
        console.log(this.NumberType);
        this.valid = true;
        setTimeout(() => {
          this.valid = false;
          //Setting up the routes
          this.$router.push(this.getContainerInfo)
        }, 1000)
      },
      OpenMap() {
        console.log("Map Opened", this.getSubmittedContainerInformation.trackingID);

        let containerNumber = this.getSubmittedContainerInformation.trackingID;
        // Opening the window
        // let route = this.$router.resolve('https://sirius.searates.com/tracking/multitracking?',
        //  'Searates',
        //  'NormalizeTo', 'Searates');
        // let route = this.$router.resolve({location: 'https://sirius.searates.com/tracking/multitracking?'});
        // let route = this.$router.resolve('/link/to/page'); // This also works.
        // window.open(route.href, '_blank');
        window.open(
          "https://sirius.searates.com/tracking/multitracking?container=" + containerNumber,
           '_blank',
           "toolbar=yes, location=yes, directories=yes, replace=true, status=no, menubar=no, scrollbars=yes, resizable=no, copyhistory=no,left=100,top=100,width=1024,height=1024",
           "replace=true");
      },
      FetchContainerInfo() {
        //Sending the instructions to the state
        // let pageId = String(this.$route.params.id);

        // ZOHO - DISABLED
        // this.$store.dispatch('GetAuthentication')

        // this.$store.dispatch('setContainerInformation', {
        //   id: pageId
        // })
      },
      RefreshContainerInfo() {
        //Getting the getters
        this.trackingInfo = this.$store.getters.containerInfo
      },
      GetURLParameter() {
        // Getting the information from the URL
        // let URLparameter = this.$route;
        const parsedParams = {};
        this.$route.hash.split('&')
          .map(part => part.replace(/^#/, ''))
          .forEach(param => {
            const parts = param.split('=');
            parsedParams[parts[0]] = parts[1];
          });

        // Setting the Key Locally
        localStorage.setItem('access_token', parsedParams.access_token);
        
        return parsedParams;

      }
    },
    watch: {
      //Whenever the text field have something in it,
      // trackingID() {
      //   if(this.trackingID.length > 0) 
      //   {
      //     this.valid = true
      //   } else {
      //     this.valid = false
      //   }
      // }
    },
    computed: {
      getUserInfo(){
      return this.$store.getters.userinfo;
      },
      getUser(){
        return this.$store.getters.user;
      },
      getAppInfo(){
        return this.$store.getters.appinfo;
      },
      getLoadingState(){
        return this.$store.getters.loadingState;
      },
      getContainerInfo() {
        return this.$store.getters.containerInfo;
      },
      getSubmittedContainerInformation() {
        return this.$store.getters.submittedcontainerInfo;
      }
    },
    created() {
      // console.log('URL PARAMETERS',  parsedParams.access_token);
      this.$cookies.set('PHPSESSID','.searates.com');
      // FIREBASE
      setTimeout(() => {
        this.$store.dispatch('GetContainerInformationByContainerNumber').then(() => {
          this.overlay = false
        })
      }, 1200)



      



      // ZOHO __ DIABLED

      // IF the URL has any parameter only then it will work
      // if (this.GetURLParameter) {
      //   // console.log('Access Token', this.GetURLParameter().access_token)
      //   setTimeout(() => {
      //     this.$store.dispatch('setAccessCode', this.GetURLParameter().access_token).then(() => {
      //       // reseting the Router
      //       // this.$router.push({name: 'Search'}).then(() => {
      //       //   // Getting Authentication
      //         // this.$store.dispatch('GetAuthentication');
      //       // })
      //       this.$store.dispatch('GetAuthentication');
      //       this.overlay = this.getLoadingState.mainLoading;
      //     });
      //   },1500)
        
      // }
    }
  }
  
</script>

<style scoped>
/*Disabling the Scrolls*/
body {
  overflow: hidden;
}
.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
}
#tsparticles {
  position: absolute;
  width: 100%;
  z-index: 0;
}
img .logo {
  z-index: 2;
}
.boxColor {
   background: rgba(230,230,230,0.6); 
}
</style>