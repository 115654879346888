<template>
<div>
  <Particles
      id="tsparticles"
      style="position: absolute, width: 100%,"
      :options="{
          background: {
              color: {
                  value: 'transparent',
              }
          },
          fpsLimit: 30,
          interactivity: {
              detectsOn: 'canvas',
              events: {
                  onClick: {
                      enable: true,
                      mode: 'push'
                  },
                  onHover: {
                      enable: false,
                      mode: 'repulse'
                  },
                  resize: true
              },
              modes: {
                  bubble: {
                      distance: 600,
                      duration: 10,
                      opacity: 0.8,
                      size:32
                  },
                  push: {
                      quantity: 1
                  },
                  repulse: {
                      distance: 200,
                      duration: 0.4
                  }
              }
          },
          particles: {
              color: {
                  value: '#000'
              },
              links: {
                  color: '#444',
                  distance: 150,
                  enable: true,
                  opacity: .3,
                  width: .5
              },
              collisions: {
                  enable: false
              },
              move: {
                  direction: 'none',
                  enable: true,
                  outMode: 'none',
                  random: false,
                  speed: .2,
                  straight: true
              },
              number: {
                  density: {
                      enable: true,
                      value_area: 1200
                  },
                  value: 64
              },
              opacity: {
                  value: 0.25
              },
              shape: {
                  type: 'polygon',
                  options: {
                    polygon: [
                    {
                      sides: 3
                    }
                    ]
                  },
              },
              size: {
                  random: true,
                  value: 5
              }
          },
          detectRetina: false
      }"
    />
  <!-- CONTEXT -->
  <v-container>
     <v-row class="text-center">
      <v-col cols="12">
         <v-row>
           <v-col cols="4"></v-col>
            <v-col cols="4">
              <!-- Logo -->
              <img src="../assets/logo.png" class="my-5 mb-0 logo" height="128" />
              <h1 class="display-2 mb-1">
                {{ getAppInfo.name }}
              </h1>
              <h4 class="display-5 mb-1">
                {{ getAppInfo.fullname }} 
              </h4>
            </v-col>
            <v-col cols="4"></v-col>
          </v-row>
       </v-col>

      <!-- Tracking widget -->
      <v-col class="mb-4" cols="12">
        <v-card
          class="mx-auto"
          style="background: rgba(230,230,230,0.5);"
          elevation="20"
          max-width="400"
          :loading="valid"
        >

          <v-card-title class="mb-3" justify="center">
            <!-- Tracking ID: -->
            <h2>Registeration</h2> 
            
          </v-card-title>

          <v-card-subtitle class="mb-1">
            <h4>Please enter your credentials to register.</h4>
          </v-card-subtitle>

          <v-card-text class="text--primary">
            <v-form >
              <v-row>
                <v-col class="ml-0" cols="12">
                  <!-- Full Name -->
                  <v-text-field
                    v-model="fullname"
                    :counter="40"
                    placeholder="Full Name"
                    filled
                    name="fullname"
                    label="Full Name"
                    clearable
                    required
                    hint="Your Full Name"
                  ></v-text-field>
                  <!-- EMAIL -->
                  <v-text-field
                    v-model="email"
                    :rules="emailfieldrules"
                    :counter="40"
                    placeholder="xyz@xyz.com"
                    filled
                    label="Email"
                    name="email"
                    clearable
                    required
                    hint="Your Registered Email"
                  ></v-text-field>
                  <!-- password -->
                  <v-text-field
                    v-model="password"
                    :rules="passwordfieldrules"
                    type="password"
                    clearable
                    filled
                    minlength="8"
                    label="Password"
                    name="password"
                    required
                    hint="Your Password"
                  ></v-text-field>
                </v-col>
              </v-row>

            </v-form>
            <!-- buttons -->
            <v-list-item class="grow" >
              
              <v-col cols="3">
              </v-col>

              <v-col cols="6">
                <v-row
                justify="center"
                >
                  <v-btn
                    class="ml-2 btGradientSec"
                    elevation="15"
                    block
                    v-on:click="RegisterUser"
                  >
                    Register
                  </v-btn>
                </v-row>
              </v-col>
            </v-list-item>
          </v-card-text>
        
          <v-card-actions >
            <v-row justify="end">
              <v-btn
              class="mr-3 mb-3 btGradientSec"
              elevation="1"
              x-small
              v-on:click="AlreadyRegistered"
              >
                Already Registered
              </v-btn>
            </v-row>
          </v-card-actions>
          
        </v-card>
      </v-col>

      <v-col
        class="mb-1"
        cols="12"
      >
        <h3 class="headline font-weight-bold mb-1">
          How it works?
        </h3>

        <p>
          Please enter the reference number is required to use the services, please add container, tracking, or BL Number. <br>
          in case of any issue please contact on <a href="mailto:abc@absaco.com" >abc@absaco.com</a> <br>
          <span style="color: #999; font-weight: bolder"> 
          App Version
          {{ getAppInfo.version }}
          </span>
        </p>



      </v-col>
      

    </v-row>
  </v-container>
</div>
  
</template>

<script>

  export default {
    name: 'Registration',

    data: () => ({
    valid: false,
    dialog: false,
    snackbar: false,
    snackbarText: 'Pleases Login!',

    // META APP
    fullname: '',
    email: '',
    password: '',
    emailfieldrules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    passwordfieldrules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 8) || 'Password must be more than 8 characters'
    ]

    }),
    methods: {
      // LOGIC
      AlreadyRegistered() {
        // Setting the Dialog State
        this.$router.push({'name': 'Login'})
      },
      RegisterUser() {
        this.valid = true;
        
        // Dispatching the main Protocol
        let payload = {
          fullname: this.fullname,
          email: this.email,
          password: this.password
        }
        this.$store.dispatch('userSignUp', payload).then(() => {
          setTimeout(() => {
            this.valid = false;
            this.$router.push({'name': 'Login'})
          }, 1000)
        })
      },
    },
    computed: {
      getAppInfo(){
        return this.$store.getters.appinfo;
      },
      getAppAuth(){
        return this.$store.getters.appAuth;
      },
      getDialogText(){
        return this.$store.getters.dialogText;
      },
      getSnackBarText(){
        return this.$store.getters.snackBarText;
      }
    },
    created() {
      // Setting up the Welcome
      this.$store.dispatch('setSnackBarText', 'Welcome! Please Enter all required details')
      this.snackbarText = this.getSnackBarText;
      this.snackbar = true


      //
    }
  }
  
</script>

<style scoped>
/*Disabling the Scrolls*/
body {
  overflow: hidden;
}

#tsparticles {
  position: absolute;
  width: 100%;
  z-index: 0;
}
img .logo {
  z-index: 2;
}
.boxColor {
   background: rgba(230,230,230,0.6); 
}
</style>