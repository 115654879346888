import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueIframe from 'vue-iframes'

import { initializeApp } from 'firebase/app';

// importing Firebase
// import * as firebase from 'firebase'
// import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
// import express
// import express from "express";
// import cors
// import cors from "cors";
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router';
import { store } from './store/store'
import { routes } from './router'
import vuetify from './plugins/vuetify'
import Particles from "particles.vue";
import VueCookies from 'vue-cookies'
// import { apply } from 'core-js/fn/reflect';


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDgVzKdDr24zsQvJ6KS2ngf-sfDpcwJS3k",
  authDomain: "testapi-69d9f.firebaseapp.com",
  databaseURL: "https://testapi-69d9f.firebaseio.com",
  projectId: "testapi-69d9f",
  storageBucket: "testapi-69d9f.appspot.com",
  messagingSenderId: "453639137818",
  appId: "1:453639137818:web:51887fdc0160730e2fffa3"
};


const app = initializeApp(firebaseConfig)

Vue.config.productionTip = false

Vue.use(Particles);
Vue.use(VueRouter);
Vue.use(VueAxios, axios)
Vue.use(VueCookies)
Vue.use(VueIframe)

// Setting up base cookie
Vue.$cookies.config('BlackMesh')
Vue.$cookies.set('Type','default');
Vue.$cookies.set('Miss','default');



// Registring Routes
const router = new VueRouter({
  routes,
  mode: 'history'
});

new Vue({
  vuetify,
  store,
  router,
  app,
  render: h => h(App)
}).$mount('#app')

