<template>
<div>
  <!-- App Bar Main Container from the top black pointer -->
  <v-app-bar
    dark
  >
    <img src="../assets/logo.png"  height="32" />
    <a href="../" style="color: black; text-decoration: none"> 
      <v-toolbar-title style="color: #FFF;"> 
        <span class="ml-2"> {{ getAppInfo.name }} </span>
      </v-toolbar-title> 
    </a>
    <v-spacer></v-spacer>
    <v-btn icon>
      <v-icon>mdi-information</v-icon>
    </v-btn>
    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        gradient="to right, #000000, #434343"
      ></v-img>
    </template>
  </v-app-bar>


  <!-- loading Progress -->
  <v-container
    dark
    class="radius boxColor"
    style="box-radius: 20px;"
  >


    <!-- Page Heading -->
    <h1 class=" my-3">
          UTILITIES 
    </h1>
    <!-- Search Pager Header -->
    <v-row
    >
      <v-col
        class=""
        cols="12"
      >
      </v-col>
    </v-row>
    <v-row justify="center"
    >
      <v-col
        class="text-center"
        cols="4"
      >
        <!-- MAP EMBEDDED -->
        <h3>Modifying the Firebase DB from here</h3>
        <!-- <img src="../assets/map.png"  height="100%" /> -->
        <v-btn
          class="ml-2 my-5 btGradient"
          elevation="15"
          dark
          block
          v-on:click="Practing"
        >
          UPLOAD THE DATA!
        </v-btn>
        <v-btn
          class="ml-2 my-5 btGradient"
          elevation="15"
          dark
          block
          v-on:click="CleaupDB"
        >
          CLEAN THE DATA!
        </v-btn>
        <h3>The data is coming from the assets/json/upload.json</h3>
      </v-col>
    </v-row>
  </v-container>
  

</div>
  
</template>

<script>

  export default {
    name: 'callback',

    data: () => ({
    valid: false,

    }),
    methods: {
      Practing() {
        console.log("Data Uploaded");
        this.$store.dispatch('UploadJsonUtility')
        
        // console.log(this.NumberType);
        // this.valid = true;
        // setTimeout(() => {
        //   this.valid = false;
        //   //Setting up the routes
        //   this.$router.push(this.trackingID)
        // }, 1000)
      },
      CleaupDB() {
        console.log("Data Cleaned");
        this.$store.dispatch('CleanFirebaseDB')
        
        // console.log(this.NumberType);
        // this.valid = true;
        // setTimeout(() => {
        //   this.valid = false;
        //   //Setting up the routes
        //   this.$router.push(this.trackingID)
        // }, 1000)
        
      },
      FetchContainerInfo() {
        //Sending the instructions to the state
        // let pageId = String(this.$route.params.id);
        this.$store.dispatch('GetAuthentication')
        // this.$store.dispatch('setContainerInformation', {
        //   id: pageId
        // })
      },
      RefreshContainerInfo() {
        //Getting the getters
        this.trackingInfo = this.$store.getters.containerInfo
      }
    },
    watch: {
      //Whenever the text field have something in it,
      // trackingID() {
      //   if(this.trackingID.length > 0) 
      //   {
      //     this.valid = true
      //   } else {
      //     this.valid = false
      //   }
      // }
    },
    computed: {
      getAppInfo(){
        return this.$store.getters.appinfo;
      },
      getContainerInfo() {
        this.$store.getters.containerInfo
        return this.$store.getters.containerInfo;
      }
    },
    created() {
      // SENDING THE INFO TO THE STATE
      let pageId = String(this.$route.params.id);
      this.$store.dispatch('setContainerInformation', {
        id: pageId
      })

      //Sending the POST
          let data = new FormData();
          data.append('id', String(this.$route.params.id));
          //console.log("Setting Tracking ID: ", this.$route.params.id)
          this.axios({
          method: 'post',
          url: 'https://getabsa.techerupt.com',
          headers: {
            // "Access-Control-Allow-Origin" : "*",
            // 'Access-Control-Allow-Methods': 'POST',
            'Content-Type': 'multipart/form-data',
          },
          data: data
          }).then((response) => {
          this.trackingInfo = response.data;
          console.log(JSON.stringify(response.data))

          // After getting all the data from the Server
          
          })
    }
  }
  
</script>

<style scoped>
/*Disabling the Scrolls*/
body {
  overflow: hidden;
}
.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
}
#tsparticles {
  position: absolute;
  width: 100%;
  z-index: 0;
}
img .logo {
  z-index: 2;
}
.boxColor {
   background: rgba(230,230,230,0.6); 
}
</style>