
import Vue from 'vue'
import Router from 'vue-router'
// import express
// import express from "express";
// Components
import Home from '../components/Home.vue'
import Search from "../components/Search.vue"
import callback from "../components/callback.vue"
import Login from "../components/Login.vue"
import Registration from "../components/Registration.vue"


// init express router
// const expressRouter = express.Router();

Vue.use(Router);

export const routes = [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/search',
      name: 'Search',
      component: Search
    },
    {
      path: '/callback',
      name: 'callback',
      component: callback
    },
    {
      path: '/Login',
      name: 'Login',
      component: Login
    },
    {
      path: '/Registration',
      name: 'Registration',
      component: Registration
    },
    // {
    //   path: 'https://sirius.searates.com/tracking/multitracking?container=CBHU9587903',
    //   name: 'Searates',
    // },
    
];